import { useState } from 'react';
import memberService from '../services/memberService';
import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Alert from 'react-bootstrap/Alert';

function CreateMemberPage() {
  const emptyForm = {
    working_group: '',
    name: '',
    designation: '',
    handphone_pri: '',
    handphone_sec: '',
    email: '',
    office_email: '',
    alternate_officers: [],
  };
  const [formData, setFormData] = useState(emptyForm);

  const [alert, setAlert] = useState({
    alertVariant: '',
    alertMessage: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // const handleAlternateOfficerChange = (e, index) => {
  //   const { name, value } = e.target;
  //   const updatedAlternateOfficers = [...formData.alternate_officers];
  //   updatedAlternateOfficers[index] = {
  //     ...updatedAlternateOfficers[index],
  //     [name]: value,
  //   };
  //   setFormData((prevState) => ({
  //     ...prevState,
  //     alternate_officers: updatedAlternateOfficers,
  //   }));
  // };

  // const handleAddAlternateOfficer = () => {
  //   setFormData((prevState) => ({
  //     ...prevState,
  //     alternate_officers: [...prevState.alternate_officers, {}],
  //   }));
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await memberService.createMember(formData, sessionStorage.getItem('csrfKeyVal'));
      setAlert((prevState) => ({
        ...prevState,
        alertVariant: "success",
        alertMessage: "New member successfully added",
      }));
      setFormData(emptyForm);
    } catch (error) {
      if (error.response.status === 403) {
        setAlert((prevState) => ({
          ...prevState,
          alertVariant: "danger",
          alertMessage: "You do not have permission to create member",
        }));
      }
      console.error('Error creating member:', error);
    }
  };

  return (
    <Container>
      {
        alert.alertMessage.length > 0 &&
        <Alert variant={alert.alertVariant}>{ alert.alertMessage }</Alert>
      }

      <Form onSubmit={handleSubmit}>
        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm={4}>Group:</Form.Label>
          <Col sm={8}>
            <Form.Control type="text" id="working_group" name="working_group" value={formData.working_group} onChange={handleChange} required />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm={4}>Name:</Form.Label>
          <Col sm={8}>
            <Form.Control type="text" id="name" name="name" value={formData.name} onChange={handleChange} required />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm={4}>Designation:</Form.Label>
          <Col sm={8}>
            <Form.Control type="text" id="designation" name="designation" value={formData.designation} onChange={handleChange} required />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm={4}>Primary HP:</Form.Label>
          <Col sm={8}>
            <Form.Control type="tel" pattern="[0-9]{8}" id="handphone_pri" name="handphone_pri" value={formData.handphone_pri} onChange={handleChange} required />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm={4}>Secondary HP:</Form.Label>
          <Col sm={8}>
            <Form.Control type="tel" pattern="[0-9]{8}" id="handphone_sec" name="handphone_sec" value={formData.handphone_sec} onChange={handleChange} required />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm={4}>Email:</Form.Label>
          <Col sm={8}>
            <Form.Control type="email" id="email" name="email" value={formData.email} onChange={handleChange} required />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm={4}>Office Email:</Form.Label>
          <Col sm={8}>
            <Form.Control type="email" id="office_email" name="office_email" value={formData.office_email} onChange={handleChange} required />
          </Col>
        </Form.Group>

        {/* TODO: implement alternate officer */}
        {/* <h2>Alternate Member</h2>
        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm={4}>Name:</Form.Label>
          <Col sm={8}>
            <Form.Control type="text" id="alt-name-1" name="alt-name-1" />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm={4}>Designation:</Form.Label>
          <Col sm={8}>
            <Form.Control type="text" id="alt-designation-1" name="alt-designation-1" />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm={4}>Primary HP:</Form.Label>
          <Col sm={8}>
            <Form.Control type="tel" pattern="[0-9]{8}" id="alt-handphone_pri-1" name="alt-handphone_pri-1" />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm={4}>Secondary HP:</Form.Label>
          <Col sm={8}>
            <Form.Control type="tel" pattern="[0-9]{8}" id="alt-handphone_sec-1" name="alt-handphone_sec-1" />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm={4}>Email:</Form.Label>
          <Col sm={8}>
            <Form.Control type="email" id="alt-email-1" name="alt-email-1" />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm={4}>Office Email:</Form.Label>
          <Col sm={8}>
            <Form.Control type="email" id="alt-office_email-1" name="alt-office_email-1" />
          </Col>
        </Form.Group> */}

        <Button variant="primary" type="submit">Submit</Button>
      </Form>
    </Container>
  );
}

export default CreateMemberPage;