import Container from 'react-bootstrap/Container';
import MemberList from '../components/MemberList';

function AllMembersPage(props) {
  return (
    <Container>
      <MemberList {...props} />
    </Container>
  );
}

export default AllMembersPage;