import { useEffect, useState } from 'react';
import leaveService from '../services/leaveService';
import Container from 'react-bootstrap/Container';
import Badge from 'react-bootstrap/Badge'
import Stack from 'react-bootstrap/Stack';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Alert from 'react-bootstrap/Alert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';

function AllRecordsPage(props) {
  const [leaveRecords, setLeaveRecords] = useState([]);

  // for confirmation modal when deleting leave record
  const [showModal, setShowModal] = useState({shouldShow: false, leaveId: ''});
  const hideConfirmationModal = () => setShowModal({shouldShow: false, leaveId: ''});
  const showConfirmationModal = (leaveId) => setShowModal({shouldShow: true, leaveId: leaveId});

  const [alert, setAlert] = useState({
    alertVariant: '',
    alertMessage: '',
  });

  const fetchLeaveRecords = async () => {
    try {
      const records = await leaveService.listLeaves();
      setLeaveRecords(records);
    } catch (error) {
      console.error('Error fetching leave records:', error);
    }
  };

  useEffect(() => {
    fetchLeaveRecords();
  }, []);

  const handleDeleteRecord = async (recordId) => {
    try {
      await leaveService.deleteLeaveById(recordId, sessionStorage.getItem('csrfKeyVal'));
      setAlert((prevState) => ({
        ...prevState,
        alertVariant: "success",
        alertMessage: `Leave with ID ${recordId} has been deleted successfully`,
      }));
    } catch (error) {
      if (error.response.status === 403) {
        setAlert((prevState) => ({
          ...prevState,
          alertVariant: "danger",
          alertMessage: "You do not have permission to delete leave",
        }));
      }
      console.error('Error deleting leave record:', error);
    } finally {
      hideConfirmationModal();
      fetchLeaveRecords(); // reload the page
    }
  };

  return (
    <Container>
      {
        alert.alertMessage.length > 0 &&
        <Alert variant={alert.alertVariant}>{ alert.alertMessage }</Alert>
      }

      <Table responsive striped>
        <thead>
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Designation</th>
            <th>Email Address</th>
            <th>Alternate Officers</th>
            <th>Start Date</th>
            <th>End Date</th>
            <th>Actions</th>
          </tr>
        </thead>

        <tbody>
          {
            leaveRecords.map(
              (leave) => (
                <tr key={leave.id}>
                  <td>{ leave.id }</td>
                  <td>{ leave.name }</td>
                  <td>
                    {/* extract to components */}
                    <Stack direction="horizontal" gap={2}>
                      {
                        leave.designations.map(
                          (designation) => (
                            <Badge bg="light" text="dark" key={designation}>{ designation }</Badge>
                          )
                        )
                      }
                    </Stack>
                  </td>
                  <td>{ leave.email }</td>
                  <td>
                    <Stack direction="horizontal" gap={2}>
                      {
                        leave.alternate_member_names.map(
                          (alt) => (
                            <Badge bg="light" text="dark" key={alt}>{ alt }</Badge>
                          )
                        )
                      }
                    </Stack>
                  </td>
                  <td>{ leave.start_date }</td>
                  <td>{ leave.end_date }</td>
                  
                  <td>
                    {
                      props.userRoles.includes('User.Write') &&
                      <Button
                        variant="danger"
                        onClick={() => showConfirmationModal(leave.id)}
                      >
                        <FontAwesomeIcon icon={faTrashAlt} />
                      </Button>
                    }
                  </td>
                </tr>
              )
            )
          }
        </tbody>
      </Table>

      <Modal show={showModal.shouldShow} onHide={hideConfirmationModal}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>Do you really want to delete record { showModal.leaveId }?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={hideConfirmationModal}>
            Cancel
          </Button>
          <Button variant="danger" onClick={() => handleDeleteRecord(showModal.leaveId)}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

export default AllRecordsPage;