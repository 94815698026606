import { useEffect, useState } from 'react';
import memberService from '../services/memberService';
import Badge from 'react-bootstrap/Badge'
import Stack from 'react-bootstrap/Stack';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Alert from 'react-bootstrap/Alert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';

function MemberList(props) {
  const [members, setMembers] = useState([]);

  // for confirmation modal when deleting member
  const [showModal, setShowModal] = useState({shouldShow: false, memberId: ''});
  const hideConfirmationModal = () => setShowModal({shouldShow: false, memberId: ''});
  const showConfirmationModal = (memberId) => setShowModal({shouldShow: true, memberId: memberId});

  const [alert, setAlert] = useState({
    alertVariant: '',
    alertMessage: '',
  });

  const fetchMembers = async () => {
    try {
      const members = await memberService.listMainMembers();
      setMembers(members);
    } catch (error) {
      console.error('Error fetching members:', error);
    }
  };

  useEffect(() => {
    fetchMembers();
  }, []);

  const handleDeleteRecord = async (memberId) => {
    try {
      await memberService.deleteMemberById(memberId, sessionStorage.getItem('csrfKeyVal'));
      setAlert((prevState) => ({
        ...prevState,
        alertVariant: "success",
        alertMessage: `Member with ID ${memberId} has been deleted successfully`,
      }));
    } catch (error) {
      if (error.response.status === 403) {
        setAlert((prevState) => ({
          ...prevState,
          alertVariant: "danger",
          alertMessage: "You do not have permission to delete member",
        }));
      }
      console.error('Error deleting member', error);
    } finally {
      hideConfirmationModal();
      fetchMembers(); // reload the page
    }
  };

  return (
    <>
      {
        alert.alertMessage.length > 0 &&
        <Alert variant={alert.alertVariant}>{ alert.alertMessage }</Alert>
      }

      <Table responsive striped>
        <thead>
          <tr>
            <th>ID</th>
            <th>Working Group</th>
            <th>Name</th>
            <th>Designation</th>
            <th>Primary HP</th>
            <th>Secondary HP</th>
            <th>Email</th>
            <th>Alternate Members</th>
            <th>Actions</th>
          </tr>
        </thead>

        <tbody id="records-table-body">
          {
            members.map(
              (member) => (
                <tr key={member.id}>
                  <td>{ member.id }</td>
                  <td>
                    <Stack direction="horizontal" gap={2}>
                      {
                        member.working_groups.map(
                          (working_group) => (
                            <Badge bg="light" text="dark" key={working_group}>{ working_group }</Badge>
                          )
                        )
                      }
                    </Stack>
                  </td>
                  <td>{ member.name }</td>
                  <td>
                    <Stack direction="horizontal" gap={2}>
                      {
                        member.designations.map(
                          (designation) => (
                            <Badge bg="light" text="dark" key={designation}>{ designation }</Badge>
                          )
                        )
                      }
                    </Stack>
                  </td>
                  <td>{ member.handphone_pri }</td>
                  <td>{ member.handphone_sec }</td>
                  <td>{ member.email }</td>
                  <td>
                    <Stack direction="horizontal" gap={2}>
                      {
                        member.alternate_officers.map(
                          (alt) => (
                            <Badge bg="light" text="dark" key={alt.alternate_officer_id}>
                              <strong>{ alt.alternate_officer_name }</strong><br/>
                              { alt.alternate_officer_email }
                            </Badge>
                          )
                        )
                      }
                    </Stack>
                  </td>
                  <td>
                    {
                      props.userRoles.includes('User.Write') &&
                      <Button 
                        variant="danger"
                        onClick={() => showConfirmationModal(member.id)}
                      >
                        <FontAwesomeIcon icon={faTrashAlt} />
                      </Button>
                    }
                  </td>
                </tr>
              )
            )
          }
        </tbody>

        <Modal show={showModal.shouldShow} onHide={hideConfirmationModal}>
          <Modal.Header closeButton>
            <Modal.Title>Confirm Delete</Modal.Title>
          </Modal.Header>
          <Modal.Body>Do you really want to delete record { showModal.memberId }?</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={hideConfirmationModal}>
              Cancel
            </Button>
            <Button variant="danger" onClick={() => handleDeleteRecord(showModal.memberId)}>
              Confirm
            </Button>
          </Modal.Footer>
        </Modal>
      </Table>
    </>
  );
}

export default MemberList;