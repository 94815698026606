import axios from 'axios';

const authAxiosClient = axios.create({
  withCredentials: true,
  headers: {
    accept: 'application/json',
    'token-handler-version': '1',
  },
  baseURL: "https://mbz-bff.digiwork-lta-dev.net"
});

export default authAxiosClient;