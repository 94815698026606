import { useState, useEffect } from 'react';
import leaveService from '../services/leaveService';
import Container from 'react-bootstrap/Container';
import Table from 'react-bootstrap/Table';

function StaffAvailableToday() {
  const [availableStaff, setAvailableStaff] = useState([]);

  useEffect(() => {
    const fetchAvailableStaff = async () => {
      try {
        const availableStaff = await leaveService.listAvailableMembers();
        setAvailableStaff(availableStaff);
      } catch (error) {
        console.error('Error fetching available staff:', error);
      }
    };

    fetchAvailableStaff();
  }, []);

  return (
    <Container>
      <h2>Staff Available Today</h2>
      <Table>
        <thead>
          <tr>
            <th>Name</th>
          </tr>
        </thead>

        <tbody>
          {
            availableStaff.map(
              (staff) => (
                <tr key={staff}>
                  <td>{ staff }</td>
                </tr>
              )
            )
          }
        </tbody>
      </Table>
    </Container>
  );
}

export default StaffAvailableToday;