import { useState, useEffect } from 'react';
import memberService from '../services/memberService';
import Select from 'react-select'
import leaveService from '../services/leaveService';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Alert from 'react-bootstrap/Alert';

function LeaveForm({fetchCalendarEvents}) {
  const [formData, setFormData] = useState({
    name: '',
    appointment: '',
    email: '',
    alternate_officer: [],
    start_date: '',
    end_date: '',
  });

  const [availableNameOptions, setAvailableNameOptions] = useState([]);
  const [selectedNameOption, setSelectedNameOption] = useState([]);
  const [availableAlternateOfficerOptions, setAvailableAlternateOfficerOptions] = useState([]);
  const [selectedAlternateOfficerOptions, setSelectedAlternateOfficerOptions] = useState([]);

  const [alert, setAlert] = useState({
    alertVariant: '',
    alertMessages: [],
  });

  // helper to get full struct value from just ID
  const [memberMap, setMemberMap] = useState({});
  const [basicDetailMap, setBasicDetailMap] = useState({});

  // list all the members whose leave can be created
  useEffect(() => {
    const fetchMembers = async () => {
      try {
        const members = await memberService.listMainMembers();
        let memberOptions = [];
        let memberMap = {};
        members.forEach(
          (member) => {
            memberOptions.push({'value': member.id, 'label': member.name});
            memberMap[member.id] = member;
          }
        );
        setAvailableNameOptions(memberOptions);
        setMemberMap(memberMap);
      } catch (error) {
        console.error('Error fetching members:', error);
      }
    };

    fetchMembers();
  }, []);

  // list all possible alternate officers
  useEffect(() => {
    const fetchAlternateOfficers = async () => {
      try {
        const basicDetails = await memberService.listAllMembers();
        let basicDetailOptions = [];
        let basicDetailMap = {};
        basicDetails.forEach(
          (detail) => {
            basicDetailOptions.push({'value': detail.id, 'label': detail.name});
            basicDetailMap[detail.id] = detail;
          }
        );
        setAvailableAlternateOfficerOptions(basicDetailOptions);
        setBasicDetailMap(basicDetailMap);
      } catch (error) {
        console.error('Error fetching alternate officers:', error);
      }
    };

    fetchAlternateOfficers();
  }, []);

  // to support dynamic update of form fields
  const updateOnMemberDropdownChange = (event) => {
    setSelectedNameOption(event);
    let altOpts = [];
    let altVals = [];
    memberMap[event.value].alternate_officers.forEach(
      (alt) => {
        altOpts.push({'value': alt.alternate_officer_id, 'label': alt.alternate_officer_name});
        altVals.push({'value': alt.alternate_officer_id, 'name': alt.alternate_officer_name, 'email': alt.alternate_officer_email});
      }
    )
    setSelectedAlternateOfficerOptions(altOpts);
    setFormData((prevState) => ({
      ...prevState,
      name: event.value,
      appointment: memberMap[event.value].designations.join("; "),
      email: memberMap[event.value].email,
      alternate_officer: altVals,
    }));
  }

  const updateOnAlternateOfficerDropdownChange = (event) => {
    setSelectedAlternateOfficerOptions(event);
    let altVals = [];
    event.forEach(
      (opt) => {
        let alt = basicDetailMap[opt.value];
        altVals.push({'value': alt.id, 'name': alt.name, 'email': alt.email});
      }
    )
    setFormData((prevState) => ({
      ...prevState,
      alternate_officer: altVals,
    }));
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await leaveService.createLeave(formData, sessionStorage.getItem('csrfKeyVal'));
      setAlert((prevState) => ({
        ...prevState,
        alertVariant: "success",
        alertMessages: [response.message],
      }));
    } catch (error) {
      if (error.response.status === 400) {
        setAlert((prevState) => ({
          ...prevState,
          alertVariant: "danger",
          alertMessages: error.response.data.errors.map((ele) => (ele.message)),
        }));
      } else if (error.response.status === 403) {
        setAlert((prevState) => ({
          ...prevState,
          alertVariant: "danger",
          alertMessages: ["You do not have permission to create leave"],
        }));
      }
      console.error('Error creating leave record:', error);
    } finally {
      fetchCalendarEvents(); // reload the page
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm={4}>Name:</Form.Label>
        <Col sm={8}>
          <Select 
            id="name"
            name="name"
            value={selectedNameOption}
            onChange={updateOnMemberDropdownChange}
            options={availableNameOptions}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm={4}>Appointment:</Form.Label>
        <Col sm={8}>
          <Form.Control type="text" id="appointment" name="appointment" value={formData.appointment} readOnly />
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm={4}>Email:</Form.Label>
        <Col sm={8}>
          <Form.Control type="email" id="email" name="email" value={formData.email} readOnly />
        </Col>
      </Form.Group>

      {/* handle freeform input */}
      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm={4}>Alternate Officer:</Form.Label>
        <Col sm={8}>
          <Select
            id="alternate_officer"
            name="alternate_officer"
            value={selectedAlternateOfficerOptions}
            onChange={updateOnAlternateOfficerDropdownChange}
            isMulti
            options={availableAlternateOfficerOptions}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm={4}>Start Date:</Form.Label>
        <Col sm={8}>
          <Form.Control type="date" id="start_date" name="start_date" onChange={
            e => {
              setFormData((prevState) => ({
                ...prevState,
                start_date: e.target.value,
              }));
            }
          } required />
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm={4}>End Date:</Form.Label>
        <Col sm={8}>
          <Form.Control type="date" id="end_date" name="end_date" onChange={
            e => {
              setFormData((prevState) => ({
                ...prevState,
                end_date: e.target.value,
              }));
            }
          } required />
        </Col>
      </Form.Group>

      {
        alert.alertMessages.length > 0 &&
        <Alert variant={alert.alertVariant}>
          <ul>
            { alert.alertMessages.map(msg => <li key={msg}>{ msg }</li>)}
          </ul>
        </Alert>
      }

      <Button variant="primary" type="submit">Submit</Button>
      
      {/* reset button */}
    </Form>
  );
}

export default LeaveForm;