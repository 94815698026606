import 'bootstrap/dist/css/bootstrap.min.css';

import { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navigation from './components/Navigation';
import HomePage from './pages/HomePage';
import AllRecordsPage from './pages/AllRecordsPage';
import AllMembersPage from './pages/AllMembersPage';
import CreateMemberPage from './pages/CreateMemberPage';
import StaffAvailableToday from './pages/StaffAvailableToday';
import {ErrorRenderer} from './utilities/errorRenderer';
import {MultiTabLogout} from './utilities/multiTabLogout';

function App(props) {
  const [isPageLoaded, setIsPageLoaded] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userRoles, setUserRoles] = useState([]);
  const multiTabLogout = new MultiTabLogout(() => onExternalLogout());

  /*
    * The SPA downloads configuration, then calls the OAuth agent to see if logged in or to handle login responses
    */
  const startup = async () => {
    window.addEventListener('storage', multiTabLogout.listenForLoggedOutEvent);
    await props.viewModel.loadConfiguration();

    try {
      await props.viewModel.handlePageLoad();
      setIsPageLoaded(true);
      setIsLoggedIn(props.viewModel.sessionResponse?.isLoggedIn || false);
      setUserRoles(props.viewModel.sessionResponse?.idTokenClaims?.roles);
      multiTabLogout.initialize();
    } catch (e) {
      console.log(ErrorRenderer.toDisplayFormat(e));
    }
  }

  /*
    * Free resources when the view unloads
    */
  const cleanup = () => {
    window.removeEventListener('storage', multiTabLogout.listenForLoggedOutEvent);
  }

  /*
    * When the user logs out, the SPA raises an event to other browser tabs
    */
  const onLoggedOut = () => {
    setIsLoggedIn(false);
    multiTabLogout.raiseLoggedOutEvent();
  }

  /*
    * When there is a logout in another browser tab, this SPA instance also moves to a logged out state
    */
  const onExternalLogout = () => {
    onLoggedOut();
  }

  useEffect(() => {
    startup();
    return () => cleanup();
  }, []);


  return (
    <Router>
      {
        isPageLoaded &&
        <div>
          <Navigation 
            isLoggedIn = {isLoggedIn}
            userRoles = {userRoles}
            oauthClient={props.viewModel.oauthClient}
            onLoggedOut={() => onLoggedOut()}
          />
          {
            isLoggedIn &&
            <Routes>
              <Route path="/" element={<HomePage userRoles = {userRoles} />} />
              <Route path="/all-records" element={<AllRecordsPage userRoles = {userRoles} />} />
              <Route path="/all-members" element={<AllMembersPage userRoles = {userRoles} />} />
              <Route path="/create-member" element={<CreateMemberPage />} />
              <Route path="/staff-available-today" element={<StaffAvailableToday />} />
            </Routes>
          }
        </div>
      }
    </Router>
  );
}

export default App;