import { useEffect, useState } from 'react';
import leaveService from '../services/leaveService';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Calendar from '../components/Calendar';
import LeaveForm from '../components/LeaveForm';

function HomePage(props) {
  const [events, setEvents] = useState([]);
  const fetchEvents = async () => {
    try {
      const events = await leaveService.listLeavesForCalendar();
      setEvents(events);
    } catch (error) {
      console.error('Error fetching leave records:', error);
    }
  };
  useEffect(() => {
    fetchEvents();
  }, []);

  return (
    <Container>
      <Row>
        <Col sm={8}><Calendar calendarEvents={events} /></Col>
        { props.userRoles.includes("User.Write") && <Col sm={4}><LeaveForm fetchCalendarEvents={fetchEvents} /></Col> }
      </Row>
    </Container>
  );
}

export default HomePage;