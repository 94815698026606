import authAxiosClient from "./authAxiosClient";

const leaveService = {
  listLeavesForCalendar: async () => {
    const response = await authAxiosClient.get("list_leaves_for_calendar");
    return response.data;
  },

  listAvailableMembers: async () => {
    const response = await authAxiosClient.get("list_available_members");
    return response.data.staff_names;
  },

  listLeaves: async () => {
    const response = await authAxiosClient.get("list_leaves");
    return response.data;
  },

  createLeave: async (formData, csrfKeyVal) => {
    const response = await authAxiosClient.post("create_leave", formData, {
      headers: {
        "x-mbz-csrf": csrfKeyVal.split("=")[1]
      }
    });
    return response.data;
  },

  deleteLeaveById: async (recordId, csrfKeyVal) => {
    const response = await authAxiosClient.delete("delete_leave_by_id", {
      headers: {
        "x-mbz-csrf": csrfKeyVal.split("=")[1]
      },
      data: recordId
    });
    return response.data;
  },
};

export default leaveService;