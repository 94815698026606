import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

function Navigation(props) {
  const handleLogin = async () => {
    // If required, you can supply extra OpenID Connect parameters that are whitelisted in the OAuth Agent configuration settings
    const whiteListedOptions = {
      /*extraAuthorizationParameters: {
        ui_locales: 'sv'
      }*/
    };
    const response = await props.oauthClient.startLogin(whiteListedOptions);
    window.location.href = response.authorizationUrl;
  };

  const handleLogout = async () => {
    const csrfKeyVal = sessionStorage.getItem('csrfKeyVal');
    const logoutResponse = await props.oauthClient.logout(csrfKeyVal);
    props.onLoggedOut();
    if (logoutResponse.logoutUrl) {
      window.location.href = logoutResponse.logoutUrl;
    } else {
      window.location.href = window.location.origin
    }
  };

  return (
    <Navbar expand="lg" className="bg-body-tertiary">
      <Container>
        <Navbar.Brand href="/">
          <img
            src="/brand-logo.png"
            height="40"
            className="d-inline-block align-top"
            alt="Mobilize logo"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            {
              props.isLoggedIn &&
              <>
                <Nav.Link href="/all-records">Leaves</Nav.Link>
                <NavDropdown title="Members" id="basic-nav-dropdown">
                  <NavDropdown.Item href="/all-members">List</NavDropdown.Item>
                  { props.userRoles?.includes("User.Write") && <NavDropdown.Item href="/create-member">Create</NavDropdown.Item> }
                </NavDropdown>
                <Nav.Link href="/staff-available-today">Staff Available Today</Nav.Link>
                <Nav.Link onClick={handleLogout}>Logout</Nav.Link>
              </>
            }
            {
              !props.isLoggedIn &&
              <Nav.Link onClick={handleLogin}>Login</Nav.Link>
            }
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Navigation;