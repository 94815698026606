import authAxiosClient from "./authAxiosClient";

const memberService = {
    listMainMembers: async () => {
      const response = await authAxiosClient.get("list_main_members");
      return response.data;
    },

    listAllMembers: async () => {
      const response = await authAxiosClient.get("list_all_members");
      return response.data;
    },
  
    createMember: async (formData, csrfKeyVal) => {
      const response = await authAxiosClient.post("create_member", formData, {
        headers: {
          "x-mbz-csrf": csrfKeyVal.split("=")[1]
        }
      });
      return response.data;
    },

    deleteMemberById: async (memberId, csrfKeyVal) => {
      const response = await authAxiosClient.delete("delete_member_by_id", {
        headers: {
          "x-mbz-csrf": csrfKeyVal.split("=")[1]
        },
        data: memberId
      });
      return response.data;
    },
  };

export default memberService;